import { NextSeo } from "next-seo";
import React from "react";
import { ArticleJsonLdProps, ArticleJsonLd, BrandJsonLd, OrganizationJsonLd, WebPageJsonLd } from "next-seo";
import { Address, ArticleAuthor, ContactPoint, MetaTag, Publisher } from "next-seo/lib/types";
import { Article, Author, PageInterface } from "@/types/page";
import { SeoInterface, SeoProps } from "@/types/seoInterface";
import type { SchemaType } from "@/types/seoInterface";
import { isAlcatraz } from "@/utils/helpers";
import { AlcatrazLocales } from "@/config/supported-countries";

export function SEO({ seoData }: SeoProps): React.JSX.Element {
  // schema is handled by Schemas, pass the SeoHelper instance like this <Schemas pageData={seo} />
  return (
    <>
      <NextSeo
        title={seoData.getPageTitle()}
        description={seoData.getPageDescription()}
        canonical={seoData.getCanonicalUrl()}
        nofollow={seoData.getNoFollowStatus()}
        noindex={seoData.getNoIndexStatus()}
        openGraph={seoData.getOpenGraphData()}
        additionalMetaTags={seoData.getAdditionalMetaTags()}
      />
    </>
  );
}

export function Schemas({ seoData }: SeoProps): React.JSX.Element {
  // setting the data object specifically
  seoData.orgsData = orgData;

  return (
    <>
    {
      seoData.getSchemaTypes()?.map((schemaType: SchemaType, index: number) => {
        if (schemaType == "webpage") {
          return <WebPageSchema key={index} seoData={seoData} />;
        }
        if (schemaType == "organization") {
          return <OrganisationSchema key={index} seoData={seoData} />;
        }
        if (schemaType == "article") {
          return <ArticleSchema key={index} seoData={seoData} />;
        }
      })
    }
    </>
  )

}

// learn article pages only, web page schema should not be on this page
function ArticleSchema({ seoData }: SeoProps): React.JSX.Element {
  // https://cmcmarkets.atlassian.net/browse/WF-28219
  // not generating article schema until it's ready.
  //console.log(`Not generating organisation schema for non en-gb sites.`);
  return (<></>);
  // check if images exists
  /*
  articleBody (we need to have the parsed content)
  articleSection
  author
  datePublished
  dateModified
  headline/title
  image
  inLanguage
  publisher
  */
  return (
    <>
      <ArticleJsonLd
        articleSection={seoData.getArticleSection()}
        authorName={seoData.getAuthors()}
        datePublished={seoData.getEntryPublishedDate()}
        description={seoData.getPageDescription()}
        images={seoData.getEntryImage()}
        isAccessibleForFree={true}
        inLanguage={seoData.region}
        publisherName={seoData.getSiteName()}
        publisherLogo={seoData.getSiteLogo()}
        title={seoData.getPageTitle()}
        url={seoData.getCanonicalUrl()}
      />
    </>
  );
}

// only on the home pages
function OrganisationSchema({ seoData }: SeoProps): React.JSX.Element {
  // https://cmcmarkets.atlassian.net/browse/WF-27830
  if (seoData.region != "en-gb") {
    console.log(`Not generating organisation schema for non en-gb sites.`);
    return <></>;
  }

  return (
    <>
      <OrganizationJsonLd
        actionableFeedbackPolicy={seoData.getFeedbackPolicy()}
        address={seoData.getAddress()}
        awards={seoData.getAwards()}
        brand={seoData.getBrand()}
        contactPoint={seoData.getContactPoint()}
        description={seoData.getSiteDescription()}
        foundingDate="1989"
        knowsAbout={seoData.getKnowsAbout()}
        legalName={seoData.getSiteName("legal")}
        logo={seoData.getSiteLogo()}
        name={seoData.getSiteName()}
        sameAs={seoData.getSocialMedias()}
        url={seoData.getSiteUrlRegional()}
      />
    </>
  );
}

// all pages apart from article pages
function WebPageSchema({ seoData }: SeoProps): React.JSX.Element {
  // https://cmcmarkets.atlassian.net/browse/WF-28220
  return (
    <>
      <WebPageJsonLd
        copyrightYear={seoData.getCopyrightYear()}
        description={seoData.getPageDescription()}
        id={seoData.getCanonicalUrl()}
        name={seoData.getPageTitle()}
        publisher={seoData.getPublisher()}
        url={seoData.getCanonicalUrl()}
      />
    </>
  );
}

export class SeoHelper implements SeoInterface {
  public data: PageInterface;
  public region: string;
  private article?: Article;
  private author?: Author;
  private nofollow: boolean;
  private noindex: boolean;
  private _orgsData: Object;

  constructor(pageData: PageInterface) {
    this.data = pageData;
    // default value is "nofollow, noindex" > WF-28210
    this.nofollow = true; //this would set "index,nofollow" if noindex is set to true
    this.noindex = true; //this would set "noindex,follow" if nofollow is set to false
    if (typeof pageData.seoIndex != "undefined") {
      let seoDirectives = pageData.seoIndex.split(",");
      this.nofollow = seoDirectives[1] == "nofollow";
      this.noindex = seoDirectives[0] == "noindex";
    }
    this.region = this.mapRegion(pageData.locale.toLocaleLowerCase());
    if (
      this.data.taxonomy != undefined &&
      this.data.taxonomy.isLearn == true
    ) {
      this.article = this.data.components?.[0]?.fields;
      this.author = this.article?.author?.fields;
    }
    // check if org schema
    this._orgsData = {};
  }

  set orgsData(orgData: Object) {
    this._orgsData = Object.assign({}, orgData);
  }

  public getAddress(): Object | Address {
    return this.getSeoDataByKey({key: "offices", region: this.data.locale.toLocaleLowerCase()});
  }

  public getAdditionalMetaTags(): MetaTag[] {
    let metaTags = [];
    // it can have property, content, httpEquiv, name. content is required
    metaTags.push({
      name: "twitter:card",
      content: "summary",
    });
    metaTags.push({
      name: "twitter:creator",
      content: "@cmcmarkets",
    });
    metaTags.push({
      name: "twitter:site",
      content: "@cmcmarkets",
    });

    return metaTags;
  }

  public getArticleSection(): string {
    if (this.data.taxonomy == undefined) {
      return 'undefined';
    }

    return this.data.taxonomy.title;
  }

  public getAuthors(): ArticleAuthor[] {
    if (this.author == undefined) {
      return [];
    }

    return [
      {
        name: this.author.title,
        url: "http://url-pattern-for-authors/not-set-yet",
      },
    ];
  }

  public getAwards(): string[] {
    //return orgData.awards.default;
    const awards = this.getSeoDataByKey({key: "awards", region: this.data.locale.toLocaleLowerCase()});
    if (Array.isArray(awards) === false) {
      return [];
    }

    if (awards.length == 0) {
      return [];
    }

    return awards;
  }

  // usually Organization, Person, Product or Service schema
  public getBrand(): Object {
    return {
      id: this.getSiteUrlRegional(),
      type: "organization",
      logo: this.getSiteLogo(),
      name: this.getSiteName(),
      description: this.getPageDescription(),
      sameAs: this.getSocialMedias()
    };
  }

  public getCanonicalUrl(): string {
    // BASE_URL would provide the domain name for the FQDN
    if (this.data.slug == "home" || this.data.slug == "undefined") {
      return this.getSiteUrlRegional();
    } else {
      return this.getSiteUrlRegional() + '/' + this.data.slug;
    }
  }

  public getContactPoint(): Object[] | ContactPoint[] {
    return [this.getSeoDataByKey({key: "contactPoint", region: this.data.locale.toLocaleLowerCase()})];
  }

  public getCopyrightYear(): string {
    //make it dynamic later
    let date = new Date();
    return date.getFullYear().toString();
  }

  public getDatePublished(): string {
    // ISO 8601
    return "";
  }

  public getEntryFirstPublishedDate(): string {
    // getting first published at from ['sys']['firstPublishedAt']
    //return '2024-10-11 14:23:00';
    return this.data?.items?.[0]?.sys?.firstPublishedAt || "";
  }

  public getEntryImage(): ArticleJsonLdProps["images"] {
    // need to implement, get the featured image in different sizes
    return [];
  }

  public getEntryPublishedDate(): string {
    // getting first published at from ['sys']['firstPublishedAt']
    //return '2024-10-11 14:23:00';
    return this.data?.items?.[0]?.sys?.publishedAt || "";
  }

  public getFeedbackPolicy(): string {
    //@ts-ignore
    return this.getSeoDataByKey({key: "feedbackPolicy", region: this.region});
  }

  public getKnowsAbout(): string[] {
    //@ts-ignore
    return this.getSeoDataByKey({key: "knowsAbout", region: this.region})
  }

  public getNoFollowStatus(): boolean {
    return this.nofollow;
  }

  public getNoIndexStatus(): boolean {
    return this.noindex;
  }

  // we need method to include details to openGraph
  // https://www.npmjs.com/package/next-seo#open-graph
  public getOpenGraphData(): object {
    // twitter will read og:title, og:image, og:description, so no need to add them
    // https://www.npmjs.com/package/next-seo/v/4.24.0#twitter
    return {
      type: "website",
      url: this.getCanonicalUrl(),
      title: this.getPageTitle(),
      description: this.getPageDescription(),
      images: [],
    };
  }

  public getPageDescription(): string {
    return this.data.description;
  }

  public getPageTitle(): string {
    return this.data.title;
  }

  public getPublisher(): Object | Publisher {
    return {
      type: "organization",
      name: this.getSiteName()
    }
  }

  public getSchemaTypes(): SchemaType[] {
    const taxonomy = this.data.taxonomy;

    if (this.data.slug == 'home') {
      return ['webpage', 'organization'];
    }

    if (
      this.data.taxonomy != undefined &&
      this.data.taxonomy.isLearn == true
    ) {
      return ['article'];
    }

    return ['webpage'];
  }

  public getSiteDescription(): string {
    //@ts-ignore
    return this.getSeoDataByKey({key: "description", region: this.region});
  }

  public getSiteLogo(): string {
    return this.getSiteUrlRegional() + "/_next/image?url=https%3A%2F%2Fcdn.cmcmarkets.com%2Fneptune%2Fimages%2Fcmc-logo-navy-square.png&w=128&q=75";
  }

  public getSiteName(type = "non-legal"): string {
    if (type === "legal") {
      return "CMC Markets UK plc";
    }

    return "CMC Markets";
  }

  /**
   * return site url with the region at the end. example: https://www.cmcmarkets.com/en-gb
   */
  public getSiteUrlRegional(): string {
    return process.env.BASE_URL + '/' + this.region;
  }

  public getSeoDataByKey({key, region}: {key: string, region: string}): Object|string|string[] {
    if (region == 'en-gb') {
      region = "default";
    }

    let data = {};
    //key = `${key}.${region}`;

    try {
      data = eval(`orgData.${key}.${region}`);
      //data = this._orgsData[key][region];
    } catch (e) {
      console.error(`orgData key: ${key} region: ${region} not found`);
      console.dir(e);
    }
    //console.log(`type of data for key ${key} is :` + typeof data);
    return data;
  }

  public getSocialMedias(): string[] {
    let links = this.getSeoDataByKey({key:"socialMedias", region: this.region});
    return Object.values(links);
  }

  private mapRegion(region: AlcatrazLocales): string {
    if (isAlcatraz(region) && region == 'eo') {
      return 'en';
    }

    return region;
  }

}

export const orgData: {[key: string]: object | string[]} = {
  "awards":{
    "default":[
      "2023 WINNER No.1 Web Platform ForexBrokers.com Awards",
      "2022 WINNER Best Mobile Trading Platform ADVFN International Financial Awards",
      "2022 WINNER Best CFD Provider Online Money Awards"
    ]
  },
  "contactPoint":{
    "default":{
      "contactType": "Client Support",
      "email":"clientmanagement@cmcmarkets.co.uk",
      "telephone":"020 7170 8200"
    }
  },
  "description":{
    "default":"Spread bet and trade CFDs with CMC Markets, a leading global provider with 30 years' experience. Trade on thousands of financial instruments, across FX, indices, commodities, shares, ETFs & treasuries."
  },
  "feedbackPolicy": {
    "default": "https://www.cmcmarkets.com/en-gb/important-information"
  },
  "knowsAbout":{
    "default":[
      "Indices",
      "Forex",
      "Commodities",
      "Shares",
      "ETFs",
      "Treasuries",
      "Share Baskets",
      "CFD Trading",
      "Spread Betting",
      "Options Trading"
    ]
  },
  "offices":{
    "default":{
      "addressCountry":"GB",
      "addressLocality":"London",
      "postalCode":"EC3A 7BX",
      "streetAddress":"CMC Markets, 133 Houndsditch"
    }
  },
  "socialMedias":{
    "default":{
      "facebook":"https://www.facebook.com/cmcmarkets.uk/",
      "linkedin":"https://uk.linkedin.com/company/cmc-markets",
      "wikipedia":"https://en.wikipedia.org/wiki/CMC_Markets",
      "x":"https://x.com/CMCMarkets",
      "youtube":"https://www.youtube.com/user/CMCMarketsPLC"
    }
  }
}

